import React from "react"
import styled from 'styled-components'
import BackgroundImage from "../background-image"

const HeroImage = (props) => (
		<HeroWrapper>
			<HeroWrapperImage>
				<BackgroundImage image={props.image} />
			</HeroWrapperImage>
			<HeroInner titleText={props.titleText} >
			 	<h1>{props.titleText}</h1>
			</HeroInner>
		</HeroWrapper>
)

const HeroWrapper = styled.section`
	font-family: 'Caviar Dreams';
	width: 100%;
	display: block;
	@media (max-width: 992px){
		margin-top: 7vh;
	}
`

const HeroInner = styled.div`
	position: absolute;
	width: 100%;
	height: 85%;
	top: 0;
	left: 0;
	background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,.3), rgba(0,0,0,0));
	color: white;
	display: ${props => (props.titleText ? "flex" : "none")};
	align-items: center;
	justify-content: center;
	flex-direction: column;
	font-size: 5em;
	font-family: 'Caviar Dreams Bold';
	@media (max-width: 1024px) and (min-width: 785px){
		height: 80%;
		font-size: 4em;
	}
	@media (max-width: 784px) and (min-width: 541px){
		height: 60%;
		font-size: 4em;
	}
	@media (max-width: 540px){
		height: 50%;
		font-size: 2em;
	}
`

const HeroWrapperImage = styled.div`
	@media (min-width: 992px){
		height: 700px;
	}
`

export default HeroImage;
