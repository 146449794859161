module.exports = {
	/////// NAVIGATION////////
	"origensLink": "/en/origens",
	"origensLabel": "Origins",
	"cuinaLink": "/en/cuina",
	"cuinaLabel": "Food",
	"espaisLink": "/en/espais",
	"espaisLabel": "Spaces",
	"cellerLink": "/en/celler",
	"cellerLabel": "Cellar",
	"cartaLink": "/en/carta",
	"cartaLabel": "Menu",
	"noticiesLink": "/en/noticies",
	"noticiesLabel": "News",
	"contacteLink": "/en/contacte",
	"contacteLabel": "Contact",
	"reservesLink": "/en/reserves",
	"reservesLabel": "Bookings",

	/////// FOOTER////////
	"direccioCarrer": "Ponent 20",
	"direccioPoblacio": "Sant Pau d'Ordal 08739 BCN",
	"labelGoogleMaps": "google maps",
	"numTelefon":"+34 93 899 30 28",
	"email": "info@calperedelmaset.com",
	"diesDinarOberts": "Tuesday to Sunday",
	"horesDinarOberts": "13:00 to 16:00",
	"diesSoparOberts": "Friday and Saturday",
	"horesSoparOberts": "21:00h to 23:00h",
	"diaTancat": "Monday closed",
	"segueixnos": "FOLLOW US",
	"treballaAmbNosaltres": "Work with us",
	"privacitat": "PRIVACY",
	"condicionsDus": "TERMS OF USE",

	/////// ORIGENS////////
	"heroText": "",
	"sentenceText": "",
	"contentCaption": "",
	"contentTitle": "",
	"contentContent": "",
	"textSectionText": ""
}